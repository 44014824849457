import React from "react"
import MainLayout from "../components/MainLayout"
import max from "../data/images/maximilian_rom.jpg"
import styled from "styled-components"
import SEO from "../components/SEO"
import { colors } from "../styles/vars"

const Container = styled.div`
  display: flex;
  padding: 0 4rem;
  @media (max-width: 900px) {
    padding: 2rem 1rem;
  }
`

const Curriculum = styled.div`
  display: flex;
  background-color: white;
  margin: 2rem 0;
  flex-direction: column;
  padding: 0 4rem;
  @media (max-width: 900px) {
    padding: 0;
    & h2 {
      padding: 0 1rem;
    }
  }
`
const LeftColumn = styled.div`
  flex: 2;
  background-color: white;
`

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  @media (max-width: 769px) {
    display: none;
  }
`

const Image = styled.img`
  max-height: 20rem;
`
const Table = styled.table`
  & td {
    padding: 0.4rem 1rem;
  }
  & th {
    text-align: left;
    background-color: ${colors.LIGHTGREY};
    padding-left: 1rem;
  }
`

const TableHeadline = styled.h3`
  margin: 0;
  line-height: 3rem;
`

export default ({ location: { pathname } }) => (
  <MainLayout>
    <SEO title="Dr. Rom Facharzt für Urologie" pathname={pathname} />
    <Container>
      <LeftColumn>
        <h1>Dr. Maximilian Rom, Urologe</h1>
        <p>
          Als Facharzt für Urologie bin ich sowohl im stationären (derzeit
          Wiener Krankenanstaltenverbund, Krankenhaus Hietzing) als auch als
          Vertretungs- und Wahlarzt im niedergelassenen Bereich tätig . Ab
          Oktober 2019 eröffne ich meine neue Praxis im 23. Bezirk in Wien (Nähe
          Riverside) und führe alle Kassen.
        </p>
        <p>
          Geboren und aufgewachsen in Kärnten habe ich von 2001 bis 2007 das
          Studium der Humanmedizin an der Medizinischen Universität Wien
          absolviert. Nach Tätigkeiten als Assistenzarzt in der Facharztpraxis
          für Innere Medizin, Dr. Günter Sokol, in 1120 Wien und an der
          Abteilung für Allgemein-, Viszeral-, Thorax- und Gefäßchirurgie am
          Klinikum Deggendorf (Deutschland) absolvierte ich die Ausbildung zum
          Facharzt für Urologie an der Universitätsklinik für Urologie der
          Medizinischen Universität Wien.
        </p>
      </LeftColumn>
      <RightColumn>
        <Image src={max} alt="Dr. Maximilian Rom" />
      </RightColumn>
    </Container>
    <Curriculum>
      <h2>C U R R I C U L U M&nbsp; &nbsp;V I T A E</h2>
      <Table>
        <tbody>
          <tr>
            <th colSpan="2">
              <TableHeadline>Persönliche Daten</TableHeadline>
            </th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>Dr. Maximilian Rom</td>
          </tr>
          <tr>
            <td>
              <strong>Geboren in </strong>
            </td>
            <td>Friesach, Kärnten</td>
          </tr>
          <tr>
            <th colSpan="2">
              <TableHeadline>Beruflicher Werdegang</TableHeadline>
            </th>
          </tr>
          <tr>
            <td>Seit 2/2015</td>
            <td>Krankenhaus Hietzing, Urologische Abteilung</td>
          </tr>
          <tr>
            <td>1.6.2014</td>
            <td>Fellow of the European Board of Urology (F.E.B.U.)</td>
          </tr>
          <tr>
            <td>1.12.2013</td>
            <td>Facharzt für Urologie </td>
          </tr>
          <tr>
            <td>11/2008 – 1/2015</td>
            <td>
              Medizinische Universität Wien, Universitätsklinik für Urologie
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              -
              <em>
                Spezialgebiete: Inkontinenz, Urodynamik und
                Neurourologie&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </em>
              <br />-<em>Betreuung der Inkontinenzambulanz seit 11/2009</em>
            </td>
          </tr>
          <tr>
            <td>11/2007 – 10/2008</td>
            <td>Klinikum Deggendorf (Deutschland),</td>
          </tr>
          <tr>
            <td></td>
            <td>
              Abteilung für Allgemein-, Viszeral-, Thorax- und Gefäßchirurgie
            </td>
          </tr>
          <tr>
            <td>7/2007 – 9/2007</td>
            <td>Dr. Günter Sokol, Wien, Facharztpraxis für Innere Medizin</td>
          </tr>
          <tr>
            <th colSpan="2">
              <TableHeadline>Ausbildung</TableHeadline>
            </th>
          </tr>
          <tr>
            <td>10/2001 – 7/2007</td>
            <td>
              Studium der Humanmedizin an der Medizinischen Universität Wien{" "}
              <br /> Diplomarbeit “Die Wirkung von hyperbarer Oxygenierung auf
              das Wachstum von Staphylococcus aureus“{" "}
            </td>
          </tr>
          <tr>
            <td>9/2000 – 5/2001</td>
            <td>Grundwehrdienst</td>
          </tr>
          <tr>
            <td>6/2000</td>
            <td>Matura</td>
          </tr>
          <tr>
            <td>9/1992 – 6/2000</td>
            <td>Gymnasium Villach Peraustraße</td>
          </tr>
          <tr>
            <th colSpan="2">
              <TableHeadline>Awards</TableHeadline>
            </th>
          </tr>
          <tr>
            <td>11/2011</td>
            <td>
              Bayer Young Urology Award 2011 für die Arbeit „Comparing prostate
              cancer detection rates of end-fire and side-fire transrectal
              ultrasound probe configuration“.
            </td>
          </tr>
          <tr>
            <th colSpan="2">
              <TableHeadline>Publikationen</TableHeadline>
            </th>
          </tr>
          <tr>
            <td colSpan="2">
              <p>
                <strong>Rom M</strong>, Waldert M, Schatzl G, Swietek N, Shariat
                SF, Klatte T.{" "}
                <a href="http://www.ncbi.nlm.nih.gov/pubmed/24053594">
                  Bladder outlet obstruction (BOO) in men with
                  castration-resistant prostate cancer.
                </a>
              </p>
              BJU Int. 2013 Sep 5.
              <p>
                <strong>Rom M</strong>, Waldert M, Klingler HC, Klatte T.
                Bladder outlet obstruction in men with acute urinary retention:
                an urodynamic study. World J Urol. 2013 Jan 17.
              </p>
              <p>
                <strong>Rom M</strong>, Schatzl G, Swietek N, Rücklinger E,
                Kratzik C. World J Urol. 2013 Jan 17. Lower urinary tract
                symptoms and depression. BJU Int. 2012 Dec;110(11 Pt C).
              </p>
              <p>
                <strong>Rom M</strong>, Pycha A, Wiunig C, Reissigl A, Waldert
                M, Klatte T, Remzi M, Seitz C. Prospective randomized
                multicenter study comparing prostate cancer detection rates of
                end-fire and side-fire transrectal ultrasound probe
                configuration. Urology. 2012 Jul;80(1):15-8.{" "}
              </p>
              <p>
                Swietek N, Waldert M, <strong>Rom M</strong>, Schatzl G, Wiener
                HG, Susani M, Klatte T. The value of transurethral bladder
                biopsy after intravesical bacillus Calmette-Guérin instillation
                therapy for nonmuscle invasive bladder cancer: a retrospective,
                single center study and cumulative analysis of the literature. J
                Urol. 2012 Sep;188(3):748-53. Epub 2012 Jul 20. Review.{" "}
              </p>
              <p>
                Waldert M, Schatzl G, Swietek N, <strong>Rom M</strong>, Klatte
                T. Sex hormone-binding globulin is an independent predictor of
                biochemical recurrence after radical prostatectomy. J Urol. 2012
                Sep;188(3):792-7.
              </p>
            </td>
          </tr>
          <tr>
            <th colSpan="2">
              <TableHeadline>Mitgliedschaften</TableHeadline>
            </th>
          </tr>
          <tr>
            <td colSpan="2">
              <p>International Continence Society (ICS)</p>
              <p> European Association of Urology (EAU) </p>
              <p>
                Österreichische Gesellschaft für Urologie (ÖGU)
                <br />
                &emsp;– Arbeitskreis Blasenentleerungsstörung Medizinische
              </p>
              <p>Kontinenzgesellschaft Österreich (MKÖ) </p>
              <p>ISUG Faculty Member (Intensivseminar Urogynäkologie)</p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Curriculum>
  </MainLayout>
)
